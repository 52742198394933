export const reportSections = [{
  id: 'executive_summary',
  reportTitle: 'Executive Summary',
  toolTip: 'Switching on will show the Executive summary in the reports',
},
{
  id: 'member_engagement',
  reportTitle: 'Member engagement',
  toolTip: 'Switching on will show the Member engagement in the reports',
},
{
  id: 'survey_insights',
  reportTitle: 'Survey insights',
  toolTip: 'Switching on will show the Survey insights in the reports',
}]